<template>
  <div class="px-2 text-[0.333rem] leading-normal" style="cursor: grab;user-select: none;">
    <div class="text-[.444rem] mt-3 text-black">
      <Title label="My Account">
        <div class="flex items-center">
          <img @click="() => {
            this.$emit('getUserAssets')
          }" width="16" src="@/svg/swap-refresh.svg" class="w-[0.3rem] h-[0.3rem]">
        </div>
      </Title>
      <div class="mt-1 px-1 mb-3" style="filter: hue-rotate(77deg);">
        <div class="flex items-center h-2 mb-1.5">
          <p class="text-[#282b3a80] text-[.38rem] text-grey50 mr-auto"> Total Output </p>
          <div class="flex items-center">
            <p class="text-[#1873b9] text-[.38rem] mr-1">
              <countTo
                :startVal="0"
                :endVal="totalAmt"
                :decimals="getMinLength(totalAmt)"
                :duration="1000" />
            </p>
            <img src="@/svg/eth_grey.svg" width="14" class="w-[0.3rem] h-[0.3rem]">
          </div>
        </div>
        <div class="flex items-center h-2 mb-1.5">
          <p class="text-[#282b3a80] text-[.38rem] text-grey50 mr-auto"> Exchangeable </p>
          <div class="flex items-center">
            <p class="text-[#1873b9] text-[.38rem] mr-1">
              <countTo
                :startVal="0"
                :endVal="exchangeableAmt"
                :decimals="getMinLength(exchangeableAmt)"
                :duration="1000" />
            </p>
            <img src="@/svg/eth_grey.svg" width="14" class="w-[0.3rem] h-[0.3rem]">
          </div>
        </div>
        <div class="flex items-center h-2 mb-1.5">
          <p class="text-[#282b3a80] text-[.38rem] text-grey50 mr-auto"> Wallet Balance </p>
          <div class="flex items-center">
            <p class="text-[#1873b9] text-[.38rem] mr-1">
              <countTo
                :startVal="0"
                :endVal="balanceAmt"
                :decimals="getMinLength(balanceAmt)"
                :duration="1000" />
            </p>
            <img src="@/svg/usdt_grey.svg" width="14" class="w-[0.3rem] h-[0.3rem]">
          </div>
        </div>
      </div>
      <Title label="Recharge USDT">
        <div class="flex items-center">
          <img width="16" src="@/svg/swap-refresh.svg" class="w-[0.3rem] h-[0.3rem]">
        </div>
      </Title>
      <div class="flex flex-col">
        <div class="flex items-center justify-between border-b border-[#C8C9CC] py-[12px]">
          <div class="text-[#666] text-[.38rem]">Payment Currency</div>
          <div class="flex items-center" @click="showPicker = true">
            <p class="mr-[0.22rem] text-[.33rem]">{{ Currency }}</p>
            <i class="van-badge__wrapper van-icon van-icon-arrow-down" style="color: rgb(173, 173, 173);">
            </i>
          </div>
        </div>
        <div class="flex items-center justify-between border-b border-[#C8C9CC] py-[12px]">
          <p class="text-[#666] text-[.33rem]">USDT AMOUNT</p>
          <div class="flex items-center">
            <input ref="pool-input" class="input-my text-right text-[.33rem]" type="number" placeholder="Enter Amount of USDT recharge">
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <button @click="handlePay" class="bg-[#FE8639] w-[6rem] h-[1.1733rem] text-[.333rem] rounded-[.25rem] text-[#fff] flex justify-center items-center my-2"> Pay </button>
      </div>
      <!-- van-popup -->
      
      <Title label="Record"></Title>
      <div class="border-4 border-[#E5E6EA] rounded-[20px] w-full flex items-center justify-center h-[60vh] overflow-hidden mt-[.375rem] mb-[1.8rem]">
        <ul class="list-ul w-full">
          <li v-if="!UserAssetsLogList || !UserAssetsLogList.length" class="list-item">
            <span style="font-size:0.333rem">empty...</span>
            <span></span>
          </li>
          <li v-for="(item, index) in UserAssetsLogList" :key="`${item.uid}${index}`" class="list-item">
            <span style="font-size:0.333rem">
              {{ `Output +${item.transAmount} ETH` }}
            </span>
            <!-- color: item.transAmount >= 0 ? '#07c760' : '#ee0a24' -->
            <span style="font-size:0.333rem">{{ item.createTime }}</span>
          </li>
        </ul>
      </div>
    </div>
    <van-popup v-model="showPicker" get-container="body" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns.map(item => item.key)"
        confirm-button-text="confirm"
        cancel-button-text="cancel"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showPay" style="border-radius:0" closeable get-container="body" position="bottom" round>
      <Pay
        :Currency="Currency"
        :payTo="payTo"
        :amount="amount"
        :orderId="orderId"
        ref="pay"
        @pay="payEmit"></Pay>
    </van-popup>
  </div>
</template>

<script>

import countTo from 'vue-count-to'
import Title from './Title.vue'
import Pay from './Pay.vue'

import {
  requestCharge
} from '@/api/user.js'
export default {
  name: 'Pool',
  components: {
    countTo,
    Title,
    Pay
  },
  props: {
    totalAmt: {
      type: Number,
      default: 0
    },
    exchangeableAmt: {
      type: Number,
      default: 0
    },
    balanceAmt: {
      type: Number,
      default: 0
    },
    cofigData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    UserAssetsLogList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      Currency: 'USDT_ERC20',
      payTo: '',
      amount: '',
      orderId: '',
      showPicker: false,
      showPay: false
    }
  },
  methods: {
    init() {
    },
    getMinLength(num) {
      const dian = (num + '').indexOf('.')
      if (dian === -1) return 0
      const res = (num + '').slice(dian + 1).length
      return res > 6 ? 6 : res
    },
    async handlePay() {
      const input = this.$refs['pool-input']
      console.log(1111)
      if (!input.value || Number(input.value) <= 0) {
        this.$notify({
          type: 'warning',
          message: 'Please enter the recharge amount.'
        })
        return
      }
      if (!this.Currency) {
        this.$notify({
          type: 'warning',
          message: 'Please login or refresh.'
        })
        return
      }
      console.log(2222)
      // this.payTo
      const { AddressErc20, AddressOmni, AddressTrc20 } = this.cofigData
      const type = this.Currency.slice(this.Currency.indexOf('_') + 1)
      if (type === 'ERC20') {
        this.payTo = AddressErc20
      }
      if (type === 'OMNI') {
        this.payTo = AddressOmni
      }
      if (type === 'TRC20') {
        this.payTo = AddressTrc20
      }
      if (!this.payTo) {
        this.$notify({
          type: 'warning',
          message: 'Please login or refresh.'
        })
        return
      }
      let rand = Math.floor((Math.random()*400)+13)
      if (rand + '' == 2) {
        rand = `0000${rand}`
      } else {
        rand = `000${rand}`
      }
      console.log(33333)
      this.amount = +(+input.value + `.${rand}`)
      this.orderId = ''
      await requestCharge({
        chargeAmount: +input.value,
        randomAmout: this.amount,
        coinName: this.Currency.slice(0, this.Currency.indexOf('_')),
        coinType: this.Currency.slice(this.Currency.indexOf('_') + 1)
      }).then(({ data }) => {
        // const dataList = data
        const { id } = data
        this.orderId = id
        this.showPay = true
        this.$nextTick(() => {
          this.$refs.pay.init()
        })
      }).catch(err => {
        console.log(err, 'errrr')
      })
    },
    payEmit() {
      this.$emit('pay', this.amount, this.payTo, this.orderId, this.Currency.slice(0, this.Currency.indexOf('_')))
    },
    onConfirm(value) {
      this.Currency = value
      this.showPicker = false;
    }
  },
  mounted() {
  }
};
</script>

<style>
</style>