import request from '@/utils/request'

export function facadeIn(data) {
  return request({
    url: '/aave/facade/in',
    method: 'post',
    data
  })
}

export function getUserAssets(data) {
  return request({
    url: '/aave/facade/user/info/getUserAssets',
    method: 'post',
    data
  })
}

export function getUserAssetsLog(data) {
  return request({
    url: '/aave/facade/user/info/getUserAssetsLog',
    method: 'post',
    data
  })
}

// 冲币
export function requestCharge(data) {
  return request({
    url: '/aave/facade/user/charge/requestCharge',
    method: 'post',
    data
  })
}

export function cancelPay(data) {
  return request({
    url: '/aave/facade/user/charge/cancel',
    method: 'post',
    data
  })
}




export function getPool(data) {
  return request({
    url: '/aave/common/client/getPool',
    method: 'post',
    data
  })
}

export function config(data) {
  return request({
    url: '/aave/common/system/config',
    method: 'post',
    data
  })
}

// 转金额
export function quickTrans(data) {
  return request({
    url: '/aave/facade/user/trans/quickTrans ',
    method: 'post',
    data
  })
}

// 提现
export function requestDraw(data) {
  return request({
    url: '/aave/facade/user/draw/requestDraw',
    method: 'post',
    data
  })
}

// 获取最新的返利数据
export function getUserIncomeRelease(data) {
  return request({
    url: '/aave/common/getUserIncomeRelease',
    method: 'post',
    data
  })
}

// 汇率
export function getRate(data) {
  return request({
    url: '/aave/common/market/getRate',
    method: 'post',
    data
  })
}

export function getDrawList(data) {
  return request({
    url: '/aave/facade/user/draw/getDrawList',
    method: 'post',
    data
  })
}

export function getHotBalance(data) {
  return request({
    url: '/aave/facade/user/getHotBalance',
    method: 'post',
    data
  })
}


