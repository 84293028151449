<template>
  <section class="hero bg-no-repeat bg-center h-8rem text-white text-base text-center">
    <nav class="w-full pt-1 px-1.5 flex items-center justify-between relative">
      <img src="@/svg/menu.svg">
      <div class="absolute left-1/2 top-1/2 h-2.5 transform -translate-x-1/2 -translate-y-1">
        <img class="h-2.5" style="vertical-align:top" src="@/assets/images/logo.png">
        <span class="pt-title-name">{{ titleName }}</span>
      </div>
      <div @click="onWalletSelected" class="anchor rounded h-2.5 flex items-center p-0.5">
        <img src="@/svg/anchor.svg" class="block text-xss">
        <span class="mx-0.5 whitespace-nowrap text-xss">{{ account ? account.slice(0, 7) : 'Connect Wallet' }}</span>
      </div>
    </nav>
    <slot></slot>
  </section>
</template>

<script>

export default {
  name: 'TopBlock',
  props: {
    account:{
      type: String,
      default: ''
    },
    titleName:{
      type: String,
      default: ''
    }
  },
  components: {
  },
  methods: {
    onWalletSelected(val) {
      // this.$emit('onWalletSelected', 'metamask')
      this.$emit('onWalletSelected', 'coinbaseWallet')
    }
  },
};
</script>

<style>
.pt-title-name {
  font-size: 0.65rem;
  line-height: 0.65rem;
  vertical-align: text-top;
  margin-left: 8px;
  font-weight: bolder;
  background: linear-gradient(to right, #8ed4ff, #7251f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>