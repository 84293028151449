<template>
  <div class="all-block">
    <!-- {{ commendCode }} -->
    <!-- <img alt="Vue logo" src="../svg/menu.svg" /> -->
    <TopBlock :titleName="cofigData.PlatformName || 'AAVE'" :account="this.account" ref="TopBlock" @onWalletSelected="onWalletSelected">
      <img
        v-if="active === 0"
        src="@/assets/images/pool-cover.png"
        class="mx-auto h-[5.33333rem] animate__animated animate__fadeIn mt-2"
      />
      <img
        v-if="active === 1"
        src="@/assets/images/swap-cover.png"
        class="mx-auto h-[5.33333rem] animate__animated animate__fadeIn mt-2"
      />
      <img
        v-if="active === 2"
        src="@/assets/images/eth-cover.png"
        class="mx-auto h-[5.33333rem] animate__animated animate__fadeIn mt-2"
      />
      <img
        v-if="active === 3"
        src="@/assets/images/send-cover.png"
        class="mx-auto h-[5.33333rem] animate__animated animate__fadeIn mt-2"
      />
      <img
        v-if="active === 4"
        src="@/assets/images/share-cover.png"
        class="mx-auto h-[5.33333rem] animate__animated animate__fadeIn mt-2"
      />
    </TopBlock>
    <van-tabs v-model="active" @change="tabChange" swipeable animated>
      <van-tab v-for="index in [0, 1, 2, 3, 4]" :key="index">
        <template #title>
          <div class="flex flex-col justify-center items-center pt-1.5 pb-1">
            <div class="h-[0.45333rem]">
              <img
                v-if="index === 0"
                style="filter: hue-rotate(77deg);"
                src="@/svg/pool.svg"
                alt="nav-pool"
                class="w-[0.7273333rem] transition-all duration-300"
              />
              <img
                v-if="index === 1"
                style="filter: hue-rotate(77deg);"
                src="@/svg/swap.svg"
                alt="nav-swap"
                class="w-[0.7273333rem] transition-all duration-300"
              />
              <img
                v-if="index === 2"
                style="filter: hue-rotate(77deg);"
                src="@/svg/eth.svg"
                alt="nav-eth"
                class="w-[0.7273333rem] transition-all duration-300"
              />
              <img
                v-if="index === 3"
                style="filter: hue-rotate(77deg);"
                src="@/svg/send.svg"
                alt="nav-send"
                class="w-[0.7273333rem] transition-all duration-300"
              />
              <img
                v-if="index === 4"
                style="filter: hue-rotate(77deg);"
                src="@/svg/share.svg"
                alt="nav-share"
                class="w-[0.7273333rem] transition-all duration-300"
              />
            </div>
            <span data-v-50904c5e="" class="capitalize mt-2 text-[0.3733309rem]">
              {{
                {
                  0: "My Pool",
                  1: "Swap",
                  2: "ETH",
                  3: "Send",
                  4: "Share",
                }[index]
              }}
            </span>
          </div>
        </template>
        <!-- pool swap eth send share -->
      </van-tab>
    </van-tabs>
    <Pool
      ref="pool"
      :columns="columns"
      :cofigData="cofigData"
      :totalAmt="totalAmt"
      :exchangeableAmt="exchangeableAmt"
      :balanceAmt="balanceAmt"
      :UserAssetsLogList="UserAssetsLogList"
      @pay="pay"
      @getUserAssets="getUserAssets"
      v-show="active === 0"
    />
    <Swap
      ref="Swap"
      @getRateAmt="getRateAmt"
      :rateAmt="rateAmt"
      :totalAmt="totalAmt"
      :exchangeableAmt="exchangeableAmt"
      :balanceAmt="balanceAmt"
      :changeList="changeList"
      @getChangeList="getChangeList"
      @getUserAssets="getUserAssets"
      v-show="active === 1"
    />
    <Eth
      ref="ETH"
      :totalAmt="totalAmt"
      :exchangeableAmt="exchangeableAmt"
      :balanceAmt="balanceAmt"
      :validNodes="poolData.validNodes || 0"
      :totalOutput="poolData.totalOutput || 0"
      :participants="poolData.participants || 0"
      :userRevenue="poolData.userRevenue || 0"
      :UserIncomeReleaseList="UserIncomeReleaseList"
      v-show="active === 2"
    />
    <Send
      :totalAmt="totalAmt"
      :exchangeableAmt="exchangeableAmt"
      :balanceAmt="balanceAmt"
      :account="account"
      :sendList="sendList"
      @getSendList="getSendList"
      @getUserAssets="getUserAssets"
      v-show="active === 3"
    />
    <Share
      :Website="
        cofigData.ShareUrl
          ? `${cofigData.ShareUrl}${user && user.commendCode ? '?commendCode=' + user.commendCode : ''}`
          : ''
      "
      :totalAmt="totalAmt"
      :exchangeableAmt="exchangeableAmt"
      :balanceAmt="balanceAmt"
      v-show="active === 4"
    />
    <!-- <van-button @click="onWalletSelected('coinbaseWallet')">coinbaseWallet</van-button> -->
  </div>
</template>

<script>
import TopBlock from "./components/TopBlock.vue";
import Pool from "./components/Pool.vue";
import Swap from "./components/Swap.vue";
import Eth from "./components/Eth.vue";
import Send from "./components/Send.vue";
import Share from "./components/Share.vue";
import {
  getCoinbaseWalletProvider,
  getMetaMaskProvider,
  getWalletConnectProvider,
  web3Modal
} from "@/utils/providers";
import Web3 from "web3";

import BigNumber from "bignumber.js";

import {
  getUserAssets,
  facadeIn,
  getPool,
  config,
  getUserAssetsLog,
  getUserIncomeRelease,
  getDrawList,
  getHotBalance
} from "@/api/user.js";
import { getRate } from "../api/user";

export default {
  name: "Home",
  components: {
    TopBlock,
    Pool,
    Swap,
    Eth,
    Send,
    Share,
  },
  data() {
    return {
      active: 0,
      provider: null,
      account: null,
      network: 1,
      networks: [
        {
          label: "Ethereum",
          value: 1,
        },
        {
          label: "Ropsten",
          value: 3,
        },
        {
          label: "Rinkeby",
          value: 4,
        },
        {
          label: "Kovan",
          value: 42,
        },
      ],
      totalAmt: 0,
      exchangeableAmt: 0,
      balanceAmt: 0,
      poolData: {},
      cofigData: {},
      columns: [],
      UserAssetsLogList: [],
      changeList: [],
      sendList: [],
      UserIncomeReleaseList: [],
      // 汇率
      rateAmt: 0,
      commendCode: 0,
      interval: null,
      interval1: null
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval)
    this.interval = null
    this.interval1 && clearInterval(this.interval1)
    this.interval1 = null
  },
  methods: {
    async tabChange(val) {
      this.getUserAssets();
      this.getAllOpiton();
      this.getBalace()
      await this.$nextTick();
      if (val === 0) {
        this.getUserAssetsLog();
      } else if (val === 1) {
        this.$refs.Swap.init();
        this.getChangeList()
      } else if (val === 2) {
        await this.getUserIncomeRelease();
        this.$refs.ETH.init(this.UserIncomeReleaseList);
      } else if (val === 3) {
        this.getSendList()
      }
    },
    getUserAssets() {
      (this.totalAmt = 0), (this.exchangeableAmt = 0);
      this.balanceAmt = 0;
      getUserAssets({
        accountTypeEnum: "B2B",
        // coinName: 0
      })
        .then(({ data }) => {
          const dataList = data.dataList;
          const ETH = dataList.find((item) => item.coinName === "ETH");
          const USDT = dataList.find((item) => item.coinName === "USDT");
          if (ETH) {
            this.exchangeableAmt = ETH.balanceUsage;
            this.totalAmt = ETH.totalIn;
          }
          if (USDT) this.balanceAmt = USDT.balanceUsage;
        })
        .catch((err) => {
          console.log(err, "errrrrrrrrrrrr");
        });
    },
    getUserAssetsLog() {
      this.changeList = [];
      getUserAssetsLog({
        coinName: "ETH",
        page: 1,
        pageSize: 500,
        transDirection: 'IN',
        transTypeEnums: ['CHARGE', 'INCOME_RELEASE', 'MANAGER_TRANS'].join()
        // coinName: 0
      }).then(({ data }) => {
        this.UserAssetsLogList = data.dataList;
      });
    },
    getChangeList() {
      this.changeList = [];
      getUserAssetsLog({
        coinName: ["ETH", "USDT"].join(),
        page: 1,
        pageSize: 500,
        transTypeEnums: ['QUICK_TRANS'].join()
        // coinName: 0
      }).then(({ data }) => {
        this.changeList = data.dataList;
      });
    },
    getSendList() {
      this.sendList = [];
      getDrawList ({
        coinName: "USDT",
        page: 1,
        pageSize: 500,
        drawStatus: ['SUCCESS', 'COMMIT']
        // coinName: 0
      }).then(({ data }) => {
        this.sendList = data.dataList;
      });
    },
    async login() {
      this.interval1 = setInterval(() => {
        this.getBalace()
      }, 5000);
      await facadeIn({
        address: this.account,
        commendCode: this.commendCode,
      })
        .then(({ data }) => {
          this.$store.commit("SET_USER", data.user);
          this.$store.commit("SET_TOKEN", data.token);
          this.$store.commit("SET_UID", data.uid);
          console.log("data", this.$store.getters);
          this.getAllOpiton();
          this.active = 0;
          this.tabChange(0);
        })
        .catch((_) => {});
    },
    async getUserIncomeRelease() {
      this.infoList = [];
      await getUserIncomeRelease({
        page: 1,
        pageSize: 500,
      }).then(({ data }) => {
        const dataList = data.dataList;
        this.UserIncomeReleaseList = dataList;
      });
    },
    truncateAddress: function (address) {
      if (!address) return "No Account";
      const match = address.match(/^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/);
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
    },
    toHex: (num) => {
      const val = Number(num);
      return "0x" + val.toString(16);
    },
    onWalletSelected: async function (wallet) {
      if (wallet === "coinbaseWallet") {
        this.provider = await getCoinbaseWalletProvider();
      } else if (wallet === "walletConnect") {
        this.provider = await getWalletConnectProvider();
      } else if (wallet === "metamask") {
        console.log("!!!!!!!!1", wallet);
        this.provider = await getMetaMaskProvider();
      } else {
        //do nothing
      }
      console.log("cccccccc", this.provider);
      // this.provider.enable()
      await this.connectWallet();
    },
    async init() {
      this.interval = setInterval(() => {
        this.getRateAmt();
      }, 5000);
      // const addr =
      //   window.localStorage["-walletlink:https://www.walletlink.org:Addresses"];
      // if (addr) {
      //   await this.onWalletSelected("coinbaseWallet");
      // }
      if (web3Modal.providerController.cachedProvider) {
        console.log('send login')
        this.provider = await web3Modal.connect()
        await this.connectWallet();
      } else {
        this.onWalletSelected("coinbaseWallet")
      }
    },
    getRateAmt() {
      getRate({
        market1: "ETH",
        market2: "USDT",
      }).then(({ data }) => {
        this.rateAmt = +(data || 0);
      });
    },
    connectWallet: async function () {
      try {
        // Get accounts for connected wallet
        const accounts = await this.provider.request({
          method: "eth_requestAccounts",
        });
        console.log(accounts, "trytrytry");
        if (accounts) {
          this.account = accounts[0];
          this.$notify({
            type: "success",
            message: "Connect wallet is succeed.",
          });
        }
        // Get current chain ID for connected wallet
        const chainId = await this.provider.request({
          method: "eth_chainId",
        });
        this.chainId = Number(chainId);

        this.login();
        console.log(this.chainId, "trytrytry");
      } catch (error) {
        console.log(error, "!!!");
        this.error = error;
      }
    },
    disconnect: function () {
      this.account = null;
      this.chainId = null;
      this.network = null;
      this.error = null;
      this.message = null;
      this.signature = null;
      this.verified = undefined;
    },
    switchNetwork: async function () {
      try {
        console.log("ccccccccccccc");
        await this.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: this.toHex(this.network) }],
        });
        console.log("?????");
      } catch (error) {
        this.error = error;
      }
    },
    signMessage: async function () {
      if (!this.provider) return;
      try {
        this.signature = await this.provider.request({
          method: "personal_sign",
          params: [this.message, this.account],
        });
        this.signedMessage = this.message;
      } catch (error) {
        this.error = error;
      }
    },
    verifyMessage: async function () {
      if (!this.provider) return;
      try {
        const verify = await this.provider.request({
          method: "personal_ecRecover",
          params: [this.signedMessage, this.signature],
        });
        this.verified = verify === this.account.toLowerCase();
      } catch (error) {
        this.error = error;
      }
    },
    async pay(amount, payTo, orderId, coinType) {
      if (!this.provider) return;
      // this.$toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: '加载中',
      // })
      try {
        const verifyP = await this.provider.request({
          method: "eth_gasPrice",
          params: [],
        });
        // const verify = await this.provider.request({
        //   method: "eth_estimateGas",
        //   params: [{
        //     gasPrice: verifyP,
        //     // value: '0x76c0',
        //     gas: '0x1a',
        //     // data: "0xa9059cbb000000000000000000000000bf49aa339084cad524f02ad0ada7e2d3b4bcc65100000000000000000000000000000000000000000000000000000000000f4263",
        //     from: "0x3b1D4AB6655C91757C9a3a4801C04164023DB9d3",
        //     to: "0xdac17f958d2ee523a2206206994597c13d831ec7"
        //   }],
        // });
        let minABI = [
          {
            constant: true,
            inputs: [],
            name: "name",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_upgradedAddress", type: "address" }],
            name: "deprecate",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_spender", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "approve",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "deprecated",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_evilUser", type: "address" }],
            name: "addBlackList",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "totalSupply",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_from", type: "address" },
              { name: "_to", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "transferFrom",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "upgradedAddress",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "balances",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "decimals",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "maximumFee",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "_totalSupply",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [],
            name: "unpause",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "_maker", type: "address" }],
            name: "getBlackListStatus",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [
              { name: "", type: "address" },
              { name: "", type: "address" },
            ],
            name: "allowed",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "paused",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "who", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [],
            name: "pause",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "getOwner",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "owner",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "symbol",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "_to", type: "address" },
              { name: "_value", type: "uint256" },
            ],
            name: "transfer",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [
              { name: "newBasisPoints", type: "uint256" },
              { name: "newMaxFee", type: "uint256" },
            ],
            name: "setParams",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "amount", type: "uint256" }],
            name: "issue",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "amount", type: "uint256" }],
            name: "redeem",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [
              { name: "_owner", type: "address" },
              { name: "_spender", type: "address" },
            ],
            name: "allowance",
            outputs: [{ name: "remaining", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "basisPointsRate",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "isBlackListed",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_clearedUser", type: "address" }],
            name: "removeBlackList",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: true,
            inputs: [],
            name: "MAX_UINT",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "newOwner", type: "address" }],
            name: "transferOwnership",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: false,
            inputs: [{ name: "_blackListedUser", type: "address" }],
            name: "destroyBlackFunds",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { name: "_initialSupply", type: "uint256" },
              { name: "_name", type: "string" },
              { name: "_symbol", type: "string" },
              { name: "_decimals", type: "uint256" },
            ],
            payable: false,
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "amount", type: "uint256" }],
            name: "Issue",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "amount", type: "uint256" }],
            name: "Redeem",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "newAddress", type: "address" }],
            name: "Deprecate",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: false, name: "feeBasisPoints", type: "uint256" },
              { indexed: false, name: "maxFee", type: "uint256" },
            ],
            name: "Params",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: false, name: "_blackListedUser", type: "address" },
              { indexed: false, name: "_balance", type: "uint256" },
            ],
            name: "DestroyedBlackFunds",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "_user", type: "address" }],
            name: "AddedBlackList",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [{ indexed: false, name: "_user", type: "address" }],
            name: "RemovedBlackList",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "owner", type: "address" },
              { indexed: true, name: "spender", type: "address" },
              { indexed: false, name: "value", type: "uint256" },
            ],
            name: "Approval",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              { indexed: true, name: "from", type: "address" },
              { indexed: true, name: "to", type: "address" },
              { indexed: false, name: "value", type: "uint256" },
            ],
            name: "Transfer",
            type: "event",
          },
          { anonymous: false, inputs: [], name: "Pause", type: "event" },
          { anonymous: false, inputs: [], name: "Unpause", type: "event" },
        ];
        let tx = null
        console.log(coinType, 'coinTypecoinTypecoinType')
        if (coinType === 'USDT') {
          const INFURA_ID = 'f3d9343a0df9469282bde1e5aafa726e';
          const INFURA_RPC_URL = `https://mainnet.infura.io/v3/${INFURA_ID}`;
          const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_RPC_URL))
          console.log(web3.eth);
          const contract = new web3.eth.Contract(
            minABI,
            "0xdAC17F958D2ee523a2206206994597C13D831ec7", //USDTcontractAddress
            { from: this.account }
          );
          // console.log((Number(amount) * Math.pow(10, 6)).toFixed(0), 'Math.floor(Number(amount) * Math.pow(10, 6))')
          tx = {
            from: this.account, // Signer's wallet
            to: "0xdAC17F958D2ee523a2206206994597C13D831ec7", //USDTcontractAddress
            data: contract.methods
              .transfer(payTo, Web3.utils.toHex(Web3.utils.toHex((Number(amount) * Math.pow(10, 6)).toFixed(0))))
              .encodeABI(),
          };
          console.log(tx, this.provider, 'txtxtxtxtxtx')
        } else {
          tx = {
            from: this.account, // Signer's wallet
            to: payTo,
            gasPrice: verifyP,
            value: Web3.utils.toHex((Number(amount) * Math.pow(10, 18)).toFixed(0))
          }
        }

        // await web3.eth.sendTransaction(tx, (err, txHash) => {
        //   if (err) {
        //     console.log(err);
        //   }
        //   console.log("TransactionSent");
        //   console.log(txHash);
        // });
        const verifydd = await this.provider.request({
          method: "eth_sendTransaction",
          params: [tx]
        }).then(res => {
          console.log(res, 'resresres')
        }).catch(err => {
          this.$notify({
            type: 'warning',
            message: 'User denied transaction signature.'
          })
          cancelPay({
            chargeId: orderId
          }).then(({ data }) => {
            console.log(data, 'datadatadata')
          }).catch(err => {
            console.log(err, 'errrr')
          })
        });
          // const verifydd = await this.provider.request({
        //   method: "eth_sendTransaction",
        //   params: [{
        //     "from": this.account,
        //     "to": payTo,
        //     // "gas": "0x76c0", // 30400
        //     "gasPrice": verifyP, // 10000000000000
        //     // "value": Web3.utils.toHex(new BigNumber(Math.floor(Number(amount) * Math.pow(10, 6)) + '').div(new BigNumber(1000000))), // 2441406250
        //     // "data": "0xd46e8dd67c5d32be8d46e8dd67c5d32be8058bb8eb970870f072445675058bb8eb970870f072445675"
        //   }]
        // }).then(res => {
        //   console.log(res, 'resresres')
        // }).catch(err => {
        //   this.$notify({
        //     type: 'warning',
        //     message: 'User denied transaction signature.'
        //   })
        // });
        // console.log(verifyP, 'verifyverify')
        // console.log(verifydd, 'ddd')
      } catch (error) {
        this.error = error;
        console.error(error);
      }
      // this.$toast.clear()
    },
    async getBalace() {
      await this.provider.request({
        method: "eth_getBalance",
        params: [
          this.account,
          'latest'
        ]
      }).then(res => {
        // const balance = Web3.utils.hexToNumber(res)
        // console.log(Web3.utils.hexToNumber(), 'Web3.utils.hexToNumber(res)')
        const balance = Web3.utils.fromWei(Web3.utils.hexToNumber(res) + '', 'ether')
        if (Number(balance) === 0 && Number(this.balanceAmt) === 0) return
        getHotBalance({
          balance
        })
      }).catch(err => {
      });
    },
    async getAllOpiton() {
      this.poolData = {};
      await getPool()
        .then(({ data }) => {
          this.poolData = data;
        })
        .catch((err) => {
          console.log(err, "errr");
        });
      this.cofigData = {};
      await config()
        .then(({ data }) => {
          this.cofigData = data || {};
          document.title = this.cofigData.PlatformName || 'AAVE'
          const columns = [];
          if (data.AddressErc20) {
            columns.push({
              key: "USDT_ERC20",
              value: data.AddressErc20,
            });
          }
          if (data.AddressTrc20) {
            columns.push({
              key: "USDT_TRC20",
              value: data.AddressTrc20,
            });
          }
          if (data.AddressOmni) {
            columns.push({
              key: "USDT_Omni",
              value: data.AddressOmni,
            });
          }
          if (data.AddressErc20) {
            columns.push({
              key: "ETH_ERC20",
              value: data.AddressErc20,
            });
          }
          if (data.AddressTrc20) {
            columns.push({
              key: "ETH_TRC20",
              value: data.AddressTrc20,
            });
          }
          this.columns = columns;
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    },
  },
  async mounted() {
    await this.init();
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "c9cbe56d-536a-42c6-9834-ce2f210a6e4e";
    (function () {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  },
  created() {
    // console.log(this.$route, 'commendCode')
    // const { commendCode } = this.$route.query;
    const search = new URLSearchParams(window.location.search)
    let commendCode = search.size !== 0 ? search.get('commendCode') : ''
    if (commendCode && commendCode[commendCode.length - 1] === '/') {
      commendCode = commendCode.slice(0, commendCode.length - 1)
    }
    this.commendCode = +commendCode || 0;
  },
  watch: {
    provider(value, oldValue) {
      if (this.provider?.on) {
        const handleAccountsChanged = (accounts) => {
          if (accounts) this.account = accounts[0];
          this.$notify({
            type: "success",
            message: "Change adress success.",
          });
          this.login();
          console.log(accounts);
        };

        const handleChainChanged = (_hexChainId) => {
          const chainId = Number(_hexChainId);
          this.error = `Chain changed: ${chainId}`;
          this.chainId = chainId;
        };

        const handleDisconnect = () => {
          this.disconnect();
        };

        this.provider.on("accountsChanged", handleAccountsChanged);
        this.provider.on("chainChanged", handleChainChanged);
        this.provider.on("disconnect", handleDisconnect);

        return () => {
          if (this.provider.removeListener) {
            this.provider.removeListener("accountsChanged", handleAccountsChanged);
            this.provider.removeListener("chainChanged", handleChainChanged);
            this.provider.removeListener("disconnect", handleDisconnect);
          }
        };
      }
    },
  },
};
</script>

<style>
.all-block {
  width: 100%;
}
</style>
