<template>
  <div class="flex p-[0.25rem] justify-center flex-col items-center text-[0.333rem]">
    <div class="w-[5rem] h-[5rem] qr-code justify-center mx-auto">
      <img ref="img-code" :src="urlCode" class="w-full" style="display: inline-block;">
    </div>
    <div class="m-[0.25rem] font-bold">Time Remaining</div>
    <div class="mb-[0.25rem] items-start self-start w-full">
      <div class="font-bold text-[0.4rem] mb-[0.1rem]"> Payment currency </div>
      <div class="input-row w-full text-[0.38rem] font-bold pl-[0.25rem] overflow-ellipsis">
        {{ Currency }}
      </div>
    </div>
    <div class="mb-[0.25rem] items-start self-start w-full">
      <div class="font-bold text-[0.4rem] mb-[0.1rem]">Pay to</div>
      <div class="flex">
        <div class="input-row w-full text-[0.38rem] font-bold pl-[0.25rem] copy-row overflow-ellipsis">
          {{ payTo }}
        </div>
        <button @click="copy(payTo, 'address')" class="copy-btn"> Copy </button>
      </div>
    </div>
    <div class="mb-[0.25rem] items-start self-start w-full">
      <div class="font-bold text-[0.4rem] mb-[0.1rem]">Amount</div>
      <div class="flex">
        <div class="input-row w-full text-[0.38rem] font-bold pl-[0.25rem] copy-row overflow-ellipsis">
          {{ amount }}
        </div>
        <button @click="copy(amount, 'amount')" class="copy-btn"> Copy </button>
      </div>
    </div>
    <div class="mb-[0.25rem] items-start self-start w-full">
      <div class="font-bold text-[0.4rem] mb-[0.1rem]">Order ID</div>
      <div class="flex">
        <div class="input-row w-full text-[0.38rem] font-bold pl-[0.25rem] copy-row overflow-ellipsis">
          {{ orderId }}
        </div>
        <button @click="copy(orderId, 'order')" class="copy-btn"> Copy </button>
      </div>
    </div>
    <div @click="init" class="mb-[0.05rem] p-[0.5rem] items-start self-start w-full warn-text text-[0.33rem]"> After the payment is successful, the payment will be returned automatically. Please confirm the payment network first </div>
    <div class="flex justify-center items-center">
      <button
        v-if="Currency.indexOf('ERC20') !== -1"
        @click="pay"
        class="bg-[#FE8639] w-[6rem] h-[1.1733rem] text-[.333rem] rounded-[.25rem] text-[#fff] flex justify-center items-center my-2">
        Pay
      </button>
    </div>
  </div>
</template>
<script>
import Qrcode from 'qrcode'
export default {
  name: 'Pay',
  props: {
    Currency: {
      type: String,
      default: ''
    },
    payTo: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  data() {
    return {
      urlCode: '',
      type: ''
    }
  },
  methods: {
    async init() {
      // this.type = 'USDT_TRC20',
      // this.payTo= 'TPD6NqXVn6HVrgBZL3V6evg6SsyUGjnVSN',
      // this.amount = '66.000236',
      // this.orderId = '5c544161-8c79-43e2-8180-9980c0881cf1'
      const url = await Qrcode.toDataURL(this.payTo || '')
      this.urlCode = url
    },
    copy(msg, info) {
      const save = function(e) {
        e.clipboardData.setData('text/plain', msg)
        e.preventDefault() // 阻止默认行为
      }
      document.addEventListener('copy', save) // 添加一个copy事件
      document.execCommand('copy') // 执行copy方法
      this.$notify({
        type: 'success',
        message: `Recharge ${info} is copied.`
      })
    },
    pay() {
      this.$emit('pay')
    }
  }
}
</script>
