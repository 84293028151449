<template>
  <div class="px-2 text-[0.333rem] leading-normal" style="cursor: grab;user-select: none;">
    <div class="text-[.444rem] mt-3 text-black">
      <Title label="Exchangeable">
        <div class="flex items-center">
          <p class="text-[#1873b9] text-[.38rem] mr-1">
            <countTo
              :startVal="0"
              :endVal="exchangeableAmt"
              :decimals="getMinLength(exchangeableAmt)"
              :duration="1000" />
          </p>
          <img width="16" src="@/svg/eth_grey.svg" class="w-[0.45rem] h-[0.45rem]">
        </div>
      </Title>
      <div class="bg-[#E8F1F8] rounded-sm flex p-2 text-xs mt-2 pt-3.5" style="filter: hue-rotate(77deg);">
        <div class="flex-1">
          <div class="h-1.5 flex items-center mb-1.5">
            <img width="22" src="@/svg/eth_grey.svg" class="w-[0.45rem] h-[0.45rem]" alt="">
            <input
              ref="moneyInput"
              @blur="moneyBlur"
              class="input-my bg-transparent w-full flex-1 h-5 text-grey50 text-left"
              type="number"
              placeholder="Amount of ETH">
          </div>
          <p @click="RedeemAll" class="text-[#1972B8] text-[0.3333rem]">
            Redeem all
          </p>
        </div>
        <div class="w-2.5">
          <img src="@/svg/swap2.svg" class="w-[0.45rem] h-auto"  style="vertical-align:top" alt="">
        </div>
        <div class="flex-1 flex-col justify-between items-center">
          <div class="h-1.5 flex justify-end items-center mb-1.5 text-left">
            <p class="text-[#1873b9] text-[.38rem] mr-1">
              <countTo
                :startVal="0"
                :endVal="resultAmt"
                :decimals="2"
                :duration="1000" />
            </p>
            <img src="@/svg/usdt_grey.svg" alt="" class="w-[0.45rem] h-[0.45rem]">
            <p class="text-[#333] font-semibold ml-1 text-[.3333rem]">
              USDT
            </p>
          </div>
          <div class="flex items-center flex justify-end text-[0.3333rem] text-[#1972B8]">
            <p class="mr-[0.2rem]">
              ETH 
              <countTo
                :startVal="0"
                :endVal="rateAmt"
                :decimals="2"
                :duration="1000" />
            </p>
            <img @click="getRateAmt" class="w-[0.33rem] h-[0.33rem]" src="@/svg/swap-refresh.svg" alt="">
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center" style="filter: hue-rotate(77deg);">
        <button @click="Exchange" class="bg-[#3842A4] w-[6rem] h-[1.1733rem] text-[.333rem] rounded-[.25rem] text-[#fff] flex justify-center items-center my-2">
          Exchange
        </button>
      </div>
      <p class="text-grey50 text-center text-[0.3333rem]"> Convert the Eth cpins you dug into USDT </p>
      <Title label="Record" class="mt-3"></Title>
      <div class="border-4 border-[#E5E6EA] rounded-[20px] w-full flex items-center justify-center h-[60vh] overflow-hidden mt-[.375rem] mb-[1.8rem]">
        <ul class="list-ul w-full">
          <li v-if="!changeList || !changeList.length" class="list-item">
            <span style="font-size:0.333rem">empty...</span>
            <span></span>
          </li>
          <li v-for="(item, index) in changeList" :key="`${item.id}${index}`" class="list-item">
            <span style="font-size:0.333rem">
              {{ `${item.transDirection === 'IN' ? 'ETH Swap' : 'Exchange'}  ${item.transAmount >= 0 ? `+${item.transAmount}` : item.transAmount}  ${item.coinName}` }}
            </span>
            <!-- color: item.transAmount >= 0 ? '#07c760' : '#ee0a24' -->
            <span style="font-size:0.333rem">{{ item.createTime }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import countTo from 'vue-count-to'
import Title from './Title.vue'

import {
  quickTrans
} from '@/api/user.js'
export default {
  name: 'Swap',
  components: {
    Title,
    countTo
  },
  props: {
    totalAmt: {
      type: Number,
      default: 0
    },
    exchangeableAmt: {
      type: Number,
      default: 0
    },
    rateAmt: {
      type: Number,
      default: 1779.19
    },
    changeList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      resultAmt: 0
    }
  },
  methods: {
    init() {
    },
    getMinLength(num) {
      const dian = (num + '').indexOf('.')
      if (dian === -1) return 0
      const res = (num + '').slice(dian + 1).length
      return res > 6 ? 6 : res
    },
    RedeemAll() {
      const moneyInput = this.$refs.moneyInput
      moneyInput.value = this.exchangeableAmt
      this.calcu()
    },
    moneyBlur() {
      this.calcu()
    },
    getRateAmt() {
      this.$emit('getRateAmt')
    },
    Exchange() {
      const moneyInput = this.$refs.moneyInput
      if (!moneyInput.value || Number(moneyInput.value) <= 0) {
        this.$notify({
          type: 'warning',
          message: 'Please enter the exchange amount.'
        })
        return
      }
      if (Number(moneyInput.value) > this.exchangeableAmt) {
        this.$notify({
          type: 'danger',
          message: 'Your ether balance is low.'
        })
        return
      }
      quickTrans({
        amount: Number(moneyInput.value),
        dstCoinName: 'USTD',
        orgCoinName: 'ETH'
      }).then(res => {
        if (res.status === 200) {
          this.$emit('getUserAssets')
          this.$emit('getChangeList')
          moneyInput.value = 0
          this.calcu()
          this.$notify({
            type: 'success',
            message: 'success.'
          })
        }
      })
    },
    calcu() {
      const moneyInput = this.$refs.moneyInput
      this.resultAmt = this.rateAmt * (moneyInput.value || 0)
    }
  }
};
</script>

<style>
</style>