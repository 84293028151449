<template>
  <div class="w-full flex items-center justify-between" style="filter: hue-rotate(77deg);">
    <div class="flex text-[.45rem]">
      <span class="num"></span>
      <p class="font-bold text-[.45rem] whitespace-nowrap">
        {{ label }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'Pool',
  props: {
    label: {
      type: String,
      default: 'laebl'
    }
  },
  components: {
  },
  methods: {
  },
};
</script>

<style>
</style>