import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, getUid } from '@/utils/auth'
import qs from 'qs'

// create an axios instance
const service = axios.create({
  //baseURL: 'http://47.75.150.55/agtService', // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken();
      config.headers['uid'] =  getUid();
    }else{
      config.headers['token'] = store.state.token;
      config.headers['uid'] =  store.state.uid;
    }
    // const addr = window.localStorage['-walletlink:https://www.walletlink.org:Addresses']
    // if (!addr) {
    //   store.dispatch('resetToken')
    // } else {
    //   if (store.getters.token) {
    //     // let each request carry token
    //     // ['X-Token'] is a custom headers key
    //     // please modify it according to the actual situation
    //     config.headers['token'] = getToken();
    //     config.headers['uid'] =  getUid();
    //   }else{
    //     config.headers['token'] = store.state.token;
    //     config.headers['uid'] =  store.state.uid;
    //   }
    // }
    if(config.method == 'post'){
      config.data = qs.stringify(config.data);
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        // this.$confirm('你已经退出，请重新登录', '确认退出', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('resetToken').then(() => {
        //     this.$router.push({path: '/'})
        //   })
        // })
      // }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res;
    }
  },
  error => {
    
    let err = null;
    if (error.response) {
	      // The request was made and the server responded with a status code
	      // that falls out of the range of 2xx
	      console.log(error.response.data.message,1);
        err = error.response.data.message;
	  } else if (error.request) {
	      // The request was made but no response was received
	      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
	      // http.ClientRequest in node.js
	      console.log(error.request,4);
        err = error.request;
	  } else {
	      // Something happened in setting up the request that triggered an Error
	      console.log('Error', error.message,5);
        err = error.message;
	  }
    return Promise.reject(err);
  }
)

export default service
