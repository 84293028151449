<template>
  <div class="px-2 text-[0.333rem] leading-normal" style="cursor: grab;user-select: none;">
    <div class="text-[.444rem] mt-3 text-black">
      <Title label=" Wallet Balance ">
        <div class="flex items-center">
          <p class="text-[#1873b9] text-[.38rem] mr-1">
            <countTo
              :startVal="0"
              :endVal="balanceAmt"
              :decimals="getMinLength(balanceAmt)"
              :duration="1000" />
          </p>
          <img width="16" src="@/svg/usdt_grey.svg" class="w-[0.45rem] h-[0.45rem]">
        </div>
      </Title>
      <div class="py-1.5">
        <div style="filter: hue-rotate(77deg);" class="bg-[#E8F1F8] withdraw-panel bg-tint h-9 rounded flex items-center justify-between p-1.5">
          <div class="flex flex-col">
            <input ref="moneyInput" class="input-my text-left bg-transparent text-grey50 text-4xl mb-1 pr-4" type="number" placeholder="Amount of USDT">
            <p @click="TotalBalance" class="text-[#1972B8] text-[0.333rem] font-bold">
              Total Balance
            </p>
          </div>
          <div class="text-[#1972B8] flex flex-none w-1/3">|</div>
          <div class="flex items-center w-1/3">
            <img src="@/svg/usdt_green.svg" alt="">
            <p class="text-[.3333rem] ml-1 font-bold">USDT</p>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <button @click="Confirm" class="bg-[#FE8639] w-[6rem] h-[1.1733rem] text-[.333rem] rounded-[.25rem] text-[#fff] flex justify-center items-center my-2">
            Confirm
          </button>
        </div>
        <p class="text-[#93959C] text-[.3333rem]"> Your withdrawal will be sent to your USDT wallet address within 24 hours in the future </p>
      </div>
      <Title label="Record"></Title>
      <div class="border-4 border-[#E5E6EA] rounded-[20px] w-full flex items-center justify-center h-[60vh] overflow-hidden mt-[.375rem] mb-[1.8rem]">
        <ul class="list-ul w-full">
          <li v-if="!sendList || !sendList.length" class="list-item">
            <span style="font-size:0.333rem">empty...</span>
            <span></span>
          </li>
          <li v-for="(item, index) in sendList" :key="`${item.id}${index}`" class="list-item">
            <span style="font-size:0.333rem">
              {{ `Withdrawal  -${item.transAmount}  ${item.coinName}` }}
            </span>
            <span style="font-size:0.333rem">{{ item.createTime }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import countTo from 'vue-count-to'
import Title from './Title.vue'

import {
  requestDraw
} from '@/api/user.js'
export default {
  name: 'Send',
  props: {
    balanceAmt: {
      type: Number,
      default: 0
    },
    account: {
      type: String,
      default: ''
    },
    sendList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    countTo,
    Title
  },
  methods: {
    getMinLength(num) {
      const dian = (num + '').indexOf('.')
      if (dian === -1) return 0
      const res = (num + '').slice(dian + 1).length
      return res > 6 ? 6 : res
    },
    TotalBalance() {
      const moneyInput = this.$refs.moneyInput
      moneyInput.value = this.balanceAmt
    },
    Confirm() {
      const moneyInput = this.$refs.moneyInput
      if (!moneyInput.value || Number(moneyInput.value) <= 0) {
        this.$notify({
          type: 'warning',
          message: 'Please enter the usdt amount.'
        })
        return
      }
      if (Number(moneyInput.value) > this.balanceAmt) {
        this.$notify({
          type: 'danger',
          message: 'Your usdt balance is low.'
        })
        return
      }
      requestDraw({
        drawAmount: Number(moneyInput.value),
        coinName: 'USDT',
        param: 2,
        toAddress: this.account
      }).then(res => {
        if (res.status === 200) {
          this.$emit('getUserAssets')
          this.$emit('getSendList')
          moneyInput.value = 0
          this.calcu()
          this.$notify({
            type: 'success',
            message: 'success.'
          })
        }
      })
    }
  },
};
</script>

<style>
</style>