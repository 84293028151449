import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";

const APP_NAME = "My Demo App";
const APP_LOGO_URL = "https://example.com/logo.png";
// const INFURA_ID = process.env.INFURA_ID;
const INFURA_ID = 'f3d9343a0df9469282bde1e5aafa726e';
const INFURA_RPC_URL = `https://mainnet.infura.io/v3/${INFURA_ID}`;
const DEFAULT_CHAIN_ID = 1;

export const getOption = {
  coinbasewallet: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: APP_NAME,
      infuraId: INFURA_ID
    }
  }
}

export const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: getOption // required
})


// Coinbase Wallet Provider
export const getCoinbaseWalletProvider = () => {
  console.log(web3Modal, getOption, 'getOptiongetOption')
  return web3Modal.connect()
  // const coinbaseWallet = new CoinbaseWalletSDK({
  //   appName: APP_NAME,
  //   appLogoUrl: APP_LOGO_URL,
  //   darkMode: false,
  //   overrideIsMetaMask: false
  // });
  // return coinbaseWallet.makeWeb3Provider(INFURA_RPC_URL, DEFAULT_CHAIN_ID);
};


// MetaMask Provider
export const getMetaMaskProvider = () => {
  // We will prefer a provider where the property `isMetaMask` is set to true
  return (
    window.ethereum?.providers?.find((p) => !!p.isMetaMask) ?? window.ethereum
  );
};

// WalletConnect Provider
export const getWalletConnectProvider = () => {
  return new WalletConnectProvider({
    infuraId: INFURA_ID
  });
};
